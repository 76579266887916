import * as React from "react"
import { graphql } from 'gatsby'
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const TermsPage = ({data}) => {
  const entry = data.prismicTermsPage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <div className="w-full bg-black rounded-br-[133px]">
        <div className="flex-none md:flex justify-between">
          <div className="w-full md:w-6/12">
            <GatsbyImage className="w-full h-full object-cover block py-12 md:py-0" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
          </div>
          <div className="w-full md:w-6/12 px-16 py-16 md:py-36">
            <div className="font-poppins leading-snug text-2xl text-white font-semibold">
              <h2 className="mb-8 leading-tight">
                <span className="bg-white text-3xl font-bold text-orange px-3 py-1 inline-block">{entry.data.tag1}</span><br />
                <span className="bg-white text-3xl font-bold text-orange px-3 py-1 inline-block">{entry.data.tag2}</span>
              </h2>
              <p className="ml-3">{entry.data.heading}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full my-16">
        <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
          {entry.data.text.map((entry, i) => (
            <div key={i}>
              {entry.background === 'white' ?
                <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
                  <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                </div>
                : ''
              }
              {entry.background === 'orange' ?
                <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug p-6 bg-orange-10">
                  <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                </div>
                : ''
              }
              {entry.background === 'diamond' ?
                <div className="font-poppins max-w-none prose prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug p-6 bg-diamond-10">
                  <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                </div>
                : ''
              }
            </div>
          ))}
        </div>
      </div>
      
      <div className="w-full relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="rounded-tl-[133px] w-full h-full block object-cover" image={entry.data.review_image.gatsbyImageData} alt={entry.data.review_image.alt ?? ''} />
        </div>
        <div className="absolute inset-0">
          <div className="flex items-end h-full">
            <div className="w-8/12">
              &nbsp;
            </div>
            <div className="w-3/12 h-full">
              <div className="w-full h-full block bg-gradient-to-l from-black to-transparent">
                &nbsp;
              </div>
            </div>
            <div className="w-3/12 h-full bg-black">
              &nbsp;
            </div>
          </div>
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex-none md:flex justify-between relative">
            <div className="w-full md:w-6/12">
              &nbsp;
            </div>
            <div className="w-full md:w-6/12 pt-12 pb-12 md:pt-72 md:pb-24 text-right">
              <div className="font-poppins leading-tight text-xl md:text-2xl font-semibold">
                <h2 className="text-white mb-6"><em>“{entry.data.review_quote}”</em></h2>
                <p className="text-orange">- {entry.data.review_author}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicTermsPage {
    data {
      meta_description
      page_title
      tag1
      tag2
      heading
      image {
        alt
        gatsbyImageData
      }
      review_image {
        alt
        gatsbyImageData
      }
      text {
        background
        text1 {
          richText
        }
      }
      review_quote
      review_author
    }
  }
}
`

export default TermsPage
